import React, { ReactElement } from 'react'
import { useMultiStyleConfig, Box, chakra } from '@chakra-ui/react'
import { RichText, ImageCard } from '@reward-platform/lift/components'
import { ContentfulIntroSection } from '@reward-platform/shared-schemas'

const IntroSection = ({ content, cards = [] }: ContentfulIntroSection): ReactElement => {
  const styles = useMultiStyleConfig('IntroSection', {})

  return (
    <Box sx={styles.container}>
      {content && (
        <Box sx={styles.content}>
          <RichText document={content} />
        </Box>
      )}
      <Box sx={styles.scroller}>
        {[...cards].map((item) => (
          <ImageCard
            key={`ImageCard_${JSON.stringify(item)}`}
            src={item.image.file?.url ?? ''}
            href={item.link ?? ''}
            alt={item.image.title}
            height={200}
            width={300}
            objectFit="cover"
            sizes="(max-width: 650px) 80vw, 300px"
            aria-hidden
          >
            <chakra.p __css={styles.heading}>{item.heading}</chakra.p>
            <chakra.p __css={styles.caption}>{item.caption}</chakra.p>
          </ImageCard>
        ))}
      </Box>
    </Box>
  )
}

export default IntroSection
