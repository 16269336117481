import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react'
import { useQuery } from 'react-query'
import debounce from 'lodash/debounce'
import { Skeleton, useMultiStyleConfig } from '@chakra-ui/react'
import { Image } from '@reward-platform/lift/components'
import { getStaticMap } from '~/services/googleService'
import { StaticMapProps } from './StaticMap.types'

const DEFAULT_MARKER_COLOUR = '#3f51b5'
const StaticMap = ({
  center,
  latitude,
  longitude,
  zoom,
  scale,
  language,
  maptype,
  format,
  displayMarker,
  displayPOI,
}: StaticMapProps): ReactElement => {
  const styles = useMultiStyleConfig('StaticMap', {})
  const markerColor = `${styles.markerColor.color}`
  const [size, setSize] = useState([0, 0])
  const queryKey = useMemo(() => {
    return {
      center,
      latitude,
      longitude,
      width: size[0],
      height: size[1],
      markerColor: markerColor || DEFAULT_MARKER_COLOUR,
      zoom,
      scale,
      language,
      maptype,
      format,
      displayMarker,
      displayPOI,
    }
  }, [
    center,
    latitude,
    longitude,
    size,
    markerColor,
    zoom,
    scale,
    language,
    maptype,
    format,
    displayMarker,
    displayPOI,
  ])

  const enabled = useMemo(() => {
    return !!(center || (latitude && longitude)) && size[0] > 0 && size[1] > 0
  }, [center, latitude, longitude, size])

  const { data } = useQuery(['staticMap', queryKey], () => getStaticMap(queryKey), {
    enabled,
  })

  const observer = useRef<ResizeObserver | undefined>(undefined)

  /* istanbul ignore next */
  if (!observer.current && typeof ResizeObserver !== 'undefined') {
    observer.current = new ResizeObserver(
      debounce((entries: ResizeObserverEntry[]) => {
        entries
          .filter(({ contentRect }) => contentRect != null)
          .forEach((entry) => {
            setSize([entry.contentRect.width, entry.contentRect.height])
          })
      }, 300)
    )
  }
  const refCallback = useCallback((el: Element | null) => {
    if (observer.current) {
      if (el) {
        observer.current.observe(el)
      } else {
        observer.current.disconnect()
      }
    }
  }, [])

  return (
    <Skeleton
      as="figure"
      ref={refCallback}
      isLoaded={!!data?.map}
      margin="0"
      width="100%"
      height="100%"
    >
      {data?.map ? (
        <Image
          __css={styles.imageWrapper}
          src={data.map}
          alt="static map"
          objectFit="fill"
          layout="fill"
          width={300}
          height={250}
        />
      ) : null}
    </Skeleton>
  )
}

export default StaticMap
