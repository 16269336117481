import {
  StaticMapOptions,
  StaticMapResponse,
} from '@reward-platform/ancillaries-schemas/static-map'
import { bffClient } from './clients/bffClient'
import { PlatformError } from '../utils/errors'

export const GET_STATIC_MAP_ERROR_POSITION =
  'Static Map must receive either a center or a latitude and longitude'
export const GET_STATIC_MAP_ERROR_SIZE = 'Static map must have a width and height'

export const getStaticMap = async ({
  center,
  latitude,
  longitude,
  width,
  height,
  markerColor,
  ...options
}: StaticMapOptions): Promise<StaticMapResponse> => {
  if (!center && (!latitude || !longitude)) {
    throw new PlatformError(GET_STATIC_MAP_ERROR_POSITION)
  }

  if (!width || !height) {
    throw new PlatformError(GET_STATIC_MAP_ERROR_SIZE)
  }

  const { data } = await bffClient({
    method: 'get',
    url: `/google/static-map`,
    params: {
      center,
      latitude,
      longitude,
      width,
      height,
      markerColor: markerColor.substring(1),
      ...options,
    },
  })

  return data as StaticMapResponse
}
